<template>
  <div class="container-fluid">
    <div class="d-flex justify-content-end mb-5">
      <div>
        <button
          class="btn btn-primary btn-sm me-2"
          type="button"
          @click="openModal({}, 'showBrandModal')"
        >
          <i class="bi bi-plus-square-fill" style="top: -3px"></i>
          新增廠商
        </button>
        <button
          class="btn btn-outline-primary btn-sm"
          type="button"
          @click="openModal({}, 'showProductModal')"
        >
          <i class="bi bi-plus-square" style="top: -3px"></i>
          新增經銷商品
        </button>
      </div>
    </div>

    <AppTable :columns="columns" :values="brands" :pages="pages" @changePage="changePage">
      <template #imagePath="props">
        <div class="px-1">
          <img
            :src="$imageFilters(props.data.imagePath)"
            :alt="props.data.nameEn"
            class="obj-fit-contain"
          />
        </div>
      </template>
      <template #products="props">
        <ul v-if="props.data?.productInfos" class="d-flex flex-wrap">
          <li v-for="item in props.data.productInfos" :key="item">
            <span class="badge border border-success text-success rounded-pill m-1">
              {{ item.nameCh }}
            </span>
          </li>
        </ul>
        <p v-else>無</p>
      </template>
      <template #name="props">
        <p class="fw-bold">{{ props.data.nameCh }}</p>
        <p>{{ props.data.nameEn }}</p>
      </template>
      <template #isAgent="props">
        <i class="bi bi-check-circle-fill fs-5 text-success" v-if="props.data.isAgent"></i>
        <i class="bi bi-x-circle-fill fs-5 text-danger" v-else></i>
      </template>
      <template #isPartner="props">
        <i class="bi bi-check-circle-fill fs-5 text-success" v-if="props.data.isPartner"></i>
        <i class="bi bi-x-circle-fill fs-5 text-danger" v-else></i>
      </template>
      <template #isPublished="props">
        <i class="bi bi-check-circle-fill fs-5 text-success" v-if="props.data.isPublished"></i>
        <i class="bi bi-x-circle-fill fs-5 text-danger" v-else></i>
      </template>

      <template #action="props">
        <button
          class="btn btn-sm btn-outline-primary fs-7"
          type="button"
          @click="$router.push({ name: 'brandDetail', params: { id: props.data.nameEn } })"
        >
          查看
        </button>
      </template>
    </AppTable>

    <AppModal v-model="showBrandModal" refName="brandModal" title="新增/編輯廠商">
      <BrandCreate :prop-items="modalData" @finishContent="finishContent('showBrandModal')" />
    </AppModal>

    <AppModal v-model="showProductModal" refName="productAgentModal" title="新增/編輯產品">
      <ProductAgentCreate
        :modal-data="modalData"
        @finishContent="finishContent('showProductModal')"
        @cancel="showProductModal = false"
      />
    </AppModal>
  </div>
</template>

<script>
import { apiQueryAdminBrandBoard } from "@/api/brand";
import BrandCreate from "@/components/BrandCreate.vue";
import ProductAgentCreate from "@/components/ProductAgentCreate.vue";

export default {
  name: "BrandList",
  components: {
    BrandCreate,
    ProductAgentCreate,
  },
  data() {
    return {
      showBrandModal: false,
      showProductModal: false,
      modalData: {},
      columns: [
        { name: "imagePath", label: "logo" },
        { name: "name", label: "名稱" },
        { name: "isAgent", label: "經銷商", className: "col-1" },
        { name: "isPartner", label: "合作夥伴", className: "col-1" },
        { name: "isPublished", label: "顯示在前台" },
        { name: "products", label: "經銷產品", className: "col-3" },
        { name: "action", label: "" },
      ],
      brands: [],
      pages: {
        totalPages: 1,
        currentPage: 1,
      },
    };
  },
  methods: {
    async queryBrand() {
      this.$vLoading(true);
      try {
        const res = await apiQueryAdminBrandBoard();
        const { code, data, message } = res.data;
        if (code === 200) {
          this.brands = data;
        } else {
          this.$vHttpsNotice({ statusCode: code, title: message });
        }
      } catch (error) {
        this.$vErrorNotice();
      } finally {
        this.$vLoading(false);
      }
    },
    changePage(page) {
      this.pages.currentPage = page;
    },
    openModal(item, modal) {
      this.modalData = item || {};
      this[modal] = true;
    },
    finishContent(modal) {
      this[modal] = false;
      this.modalData = {};
      this.queryBrand();
    },
  },
  created() {
    this.queryBrand();
  },
};
</script>
